.footer {
    width: 100%;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 20px;

    // Force footer to the bottom of the screen on short pages
    position: absolute;
    bottom: 0;
    left: 0;
    // ---
    
    .rss-icon {
        display: inline-block;
        margin-left: 20px;
    }
}