.rss-icon {
    overflow: hidden;
    height: 20px;
    width: 20px;
    position: relative;
    &-dot {
        display: inline-block;
        background-color: black;
        border-radius: 100%;
        width: 4px;
        height: 4px;
        position: absolute;
        bottom: 0px;
        left: 0px;
    }
    &-small-line {
        display: inline-block;
        border-radius: 100%;
        border: 4px solid black;
        width: 10px;
        height: 10px;
        position: absolute;
        bottom: -7px;
        left: -7px;
    }
    &-big-line {
        display: inline-block;
        border-radius: 100%;
        border: 4px solid black;
        width: 20px;
        height: 20px;
        position: absolute;
        bottom: -11px;
        left: -11px;
    }
}