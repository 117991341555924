.word-cloud {
    &-parent {
        font-size: 0.9vw;
    }
    &-grid {
        text-align: center; display: grid; grid-column-gap: 0px; grid-row-gap: 0px;
        grid-auto-rows: 2.5em; grid-auto-flow: dense;
        grid-template-columns: repeat(auto-fit, 0.625em 1fr);
    }
    &-cell {
        white-space: nowrap;
    }
}