.recommended {
    display: grid;
    list-style: none;
    padding: 0px;

    @media (min-width: 600px) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    &-item {
        margin: 10px;
        padding: 5px;
        box-shadow: 3px 3px 6px #006E00;
        display: flex;
        flex-wrap: wrap;

        &-title {
            margin: 0 0 10px 0;
        }

        &-body {
            align-self: flex-end;

            // https://css-tricks.com/line-clampin/
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        &-footer {
            display: flex;
            justify-content: space-between;
            width: 100%;

            &-tags {
                font-size: 10px;
                align-self: flex-end;
            }

            &-readmore {
                display: block;
                align-self: flex-end;
            }
        }
    }
}