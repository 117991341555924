@import 'theme-variables';

body {
    font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
    background-color: #E5FEE5;
    max-width: 1400px;

    // Force footer to the bottom of the screen on short pages
    position: relative;
    padding-bottom: 90px;
    min-height: calc(100vh - 90px);
    // ---

    margin: 0 auto;
    @include setColors;
}

.header {
    text-align: center;
    font-weight: bold;
    font-size: 40pt;
    padding-bottom: 30px;
}

.navigation {
    float: left;
    width: 200px;
}

.navigation ul {
    list-style-type: none;
}

.content {
    margin-left: 200px;
    margin-right: 60px;
    width: auto;

    img {
        max-width: 100%;
    }

    .copic-table {
        background-color: #fff;

        td {
            min-width: 15px;
        }
    }
}

pre.prettyprint {
    margin: 20px;
}

.test .header {
    border: 1px solid #ff0000;
    background: #ffeeee;
}

.test .navigation {
    border: 1px solid #0000ff;
    background: #eeeeff;
}

.test .content {
    border: 1px solid #00ff00;
    background: #eeffee;
}

.test .footer {
    border: 1px solid #000000;
    background: #eeeeee;
}

ul.linkslist {
    list-style-type: none;
}

.links-page {

    // masonry items
    .grid-sizer,
    .grid-item {
        width: 19%;
        margin-bottom: 5px;

        &.grid-item--width2 {
            width: 38%;
        }
    }

    img {
        width: 100%;
    }
}

@media (max-width: 600px) {
    .header {
        font-size: 20pt;
        padding-bottom: 0;
    }

    .navigation {
        float: none;
        width: initial;
        display: flex;
        justify-content: center;
    }

    .navigation ul {
        padding: 0;
    }

    .navigation li {
        display: inline;
        font-size: 1.4rem;
        padding: 5px
    }

    .content {
        margin-left: 5px;
        margin-right: 5px;
    }

    .links-page {

        // masonry items
        .grid-sizer,
        .grid-item {
            width: 49%;

            &.grid-item--width2 {
                width: 98%;
            }
        }
    }
}

// temp solution to make yt embeds fit on mobile
iframe {
    max-width: 100%;
}

@media (min-width: 601px) {
    .navigation ul {
        margin-right: 10px;
    }
}

@import 'fancy-nav';
//@import 'fancy-body';
@import 'word-cloud';

@import 'footer';

@import 'speciallinks';

@import 'rss-icon';

@import 'recommended.scss';

@import 'related-articles.scss';
