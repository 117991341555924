.related-articles {
    padding-top: 50px;

    &-more {
        padding-top: 20px;
    }

    &-divider {
        margin-top: 30px;
        margin-left: 20px;
        margin-right: 20px;
    }
}