
@mixin declareColors {
    --backgroundColor: #E5FEE5;
    --textColor: #000;
    --linkColor:#006E00;      /* unvisited link */
    --visitedLinkColor: #116E11;  /* visited link */
    --hoverLinkColor:#0000FF;  /* mouse over link */
    --activeLinkColor:#0000FF;  /* selected link */
}

@mixin declareInverseColors {
    --backgroundColor: #006E00;
    --textColor: #FFF;
    --linkColor:#FFF;      /* unvisited link */
    --visitedLinkColor: #CCC;  /* visited link */
    --hoverLinkColor:#0000FF;  /* mouse over link */
    --activeLinkColor:#0000FF;  /* selected link */
}

@mixin setColors {
    background-color: var(--backgroundColor);
    color: var(--textColor);
    a:link {color: var(--linkColor);}      /* unvisited link */
    a:visited {color: var(--visitedLinkColor);}  /* visited link */
    a:hover {color: var(--hoverLinkColor);}  /* mouse over link */
    a:active {color: var(--activeLinkColor);}  /* selected link */
}

:root {
    @include declareColors;
}
